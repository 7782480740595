<template>
    <div>
         <div class="container">
            <div class="pageCenter">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <div class="p-t-50 c-1 f20">佣金转到余额结果</div>
                        <!-- 有货 -->
                        <div class="content p-t-50 p-b-50">
                            <div class="text-center " style="margin-top:86px">
                                <img src="@/assets/images/stockCar/succeed.png">
                                <div class="c-8 f20 m-t-30" style="font-weight: bold;">转到余额申请成功!</div>
                                <div class="f14 m-t-15" style="color: #888;">佣金提现将到余额，请注意查收！</div>
                                <div class="finishOrder m-t-30">转出金额 ￥{{commission}}</div>
                                <el-button class="m-t-30" @click="$oucy.back()">返回</el-button>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "Enter",
    data() {
        return {
            // pageWidth: oucy.pageWidth,
            commission:this.$route.query.commission
        }
    },
    methods: {
        
    }
}
</script>
<style lang="less" scoped>

    .container{
        background:#fff;
    }

    .finishOrder {
        text-align: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2090FF;
    }
</style>